import React, { useContext, useState, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreement3C() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value.toUpperCase(),
    })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="dt-flex flex-column m-4">
        <h1 className="display-5 font-weight-bold">Appendix 3</h1>

        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50px" }}>
                <h3>3</h3>
              </td>
              <td style={{ width: "650px" }}>
                <h3>
                  Have you been or are
                  <br />
                  you suffering from any
                  <br />
                  medical condition that <br />
                  requires prolonged treatment (e.g.
                  <br />
                  diabetes, asthma, hypertension, tuberculosis, etc.)?
                  <br />
                  <br />
                  If your answer is yes,
                  <br />
                  please give details in the space provided.
                </h3>
              </td>
              <td>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appendix3_option"
                      style={{
                        transform: "scale(3)",
                        marginTop: "1em",
                      }}
                      value="Yes"
                      defaultChecked={user.appendix3_option === "Yes"}
                      onChange={e => saveField(e)}
                    />
                    <h3
                      className="form-check-label"
                      style={{ marginLeft: "1em" }}
                    >
                      Yes
                    </h3>
                  </div>
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appendix3_option"
                      style={{
                        transform: "scale(3)",
                        marginTop: "1em",
                      }}
                      value="No"
                      defaultChecked={user.appendix1_option === "No"}
                      onChange={e => {
                        saveUser({ appendix1_details: "" })
                        saveField(e)
                      }}
                    />
                    <h3
                      className="form-check-label"
                      style={{ marginLeft: "1em" }}
                    >
                      No
                    </h3>
                  </div>
                </div>

                <div className="form-group">
                  <h5 className="mt-4 form-check-label font-weight-bold">
                    If yes, please provide details below:
                  </h5>
                  <br />
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    name="appendix3_details"
                    onFocus={() => setInputName("appendix3_details")}
                    style={{
                      transform: "scale(2)",
                      marginLeft: "5em",
                      marginTop: "2em",
                      width: "40%",
                    }}
                    disabled={
                      user.appendix3_option === "No" || !user.appendix3_option
                    }
                    value={user.appendix3_details}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleKeyboard
            isActive={user.isKeyboardActive}
            onClick={() => {
              saveUser({
                ...user,
                isKeyboardActive: !user.isKeyboardActive,
              })
            }}
          />
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>
        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={
              !user.appendix3_option === "No" ||
              (user.appendix3_option === "Yes" && !user.appendix3_details)
            }
            onClick={() => {
              saveDraftUser(user)
              navigate("/contractor-agreement-3d")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-3b")}
          />
        </div>
        {user.isKeyboardActive && (
          <div
            className="fixed-bottom w-50 p-4"
            style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} Q W E R T Y U I O P [ ] \\",
                  "{lock} A S D F G H J K L ; ' {enter}",
                  "{shift} Z X C V B N M , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        )}
      </div>
    </Background>
  )
}
